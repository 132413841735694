.first {
  height: 95vh;

  background: linear-gradient(
    to right,
    #eef9fc 0%,
    #eef9fc 30%,
    #8fb6d3 30%,
    #8fb6d3 100%
  );

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-about {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
}

.left-photo {
  height: 450px;
}
.table-right {
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}
#customers {
  margin-bottom: 2em;
}
@media screen and (max-width: 900px) {
  .first {
    height: auto;
    background: none;
    color: white;
    width: 100vw;
    margin-top: 2em;

    justify-content: center;
  }
  .container-about {
    grid-template-columns: 1fr;
  }

  .table-right {
    display: grid;
    grid-template-columns: 1fr;
  }
  .right-side {
    margin-right: 5em;
    text-align: justify;
  }
  .left-side {
    margin-right: 5em;
  }
}
@media screen and (max-width: 600px) {
  .first {
    height: auto;
    background: none;
    color: black;
    margin-top: 2em;
  }
  .left-photo {
    height: 150px;
  }

  .right-side {
    margin: 0rem;
    padding: 0rem;
    font-size: 0.8rem;
  }
  .right-side {
    padding-left: 1rem;
    padding-right: 2rem;
    justify-items: center;
    font-size: 0.8rem;
  }
}
