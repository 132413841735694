#experience-header {
  text-align: center;
}

.container-experience {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  gap: 5rem;
}

.container-experience > div {
  background: var(--color-bg-variant);
  padding: 3rem 3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.container-experience > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.container-experience > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary-variant);
}

.containt-experience {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
}

.html-icon {
  color: orangered;
}

.react-icon {
  color: rgb(103, 6, 19);
}

.boot-icon {
  color: rgb(234, 8, 8);
  border-radius: 13px;
}

.css-icon {
  color: rgb(41, 10, 144);
}

.java-icon {
  border-radius: 3px;
  color: black;
}

.node-icon {
  border-radius: 3px;
  color: black;
  background: rgb(198, 198, 208);
  padding: 2px;
}

.mongo-icon {
  border-radius: 3px;
  color: skyblue;
}

.js-icon {
  border-radius: 3px;
  color: black;
}

.star {
  background: var(--color-bg-variant);
  color: yellow;
  margin: 3px;
}

.logo-name {
  text-align: center;
}

.starb {
  background: var(--color-bg-variant);
  margin: 3px;
}
.experience-details {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .container-experience {
    grid-template-columns: 1fr;
  }
  #experience {
    margin-top: 1rem;
  }
  .container-experience > div:hover {
    all: none;
  }
}

@media screen and (max-width: 600px) {
  .container-experience {
    margin: 0% !important;
    justify-content: center;
  }
  .containt-experience {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
  }
  .container-experience > div:hover {
    all: none;
  }

  #experience {
    margin-top: 1rem;
  }
}
