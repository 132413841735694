* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  background-color: #eee;
  font-family: sans-serif;
}
h1 {
  font-size: 300%;
}

section {
  padding: 10px 20px;
  margin-bottom: 20px;
  margin-left: 10px;
}
/* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.i-name > :nth-child(1) {
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(2) {
  color: var(--orange);
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(3) {
  font-weight: 100;
  font-size: 14px;
  color: var(--gray);
  margin-top: 10px;
}
.i-button {
  width: 6rem;
  height: 2rem;
}
.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 0rem;
}
.i-icons > * {
  transform: scale(0.5);
}
.i-icons > *:hover {
  cursor: pointer;
}
.img-fluid {
  max-width: 90%;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .img-fluid {
    max-width: 95%;
    overflow: hidden;
  }
  .i-icons {
    margin-top: 0;
  }
}
